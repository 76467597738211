import { format } from 'date-fns';

export const formatDate = (
  value: string | Date,
  {
    dayOfWeek = false,
    month = true,
    day = true,
    year = true,
  }: { dayOfWeek?: boolean; month?: boolean; day?: boolean; year?: boolean } = {}
) => {
  let d: number;

  if (value instanceof Date) {
    d = value.getTime();
  } else {
    d = Date.parse(value);
  }

  const dayOfWeekFormat = dayOfWeek ? 'EEEE' : undefined;
  const monthFormat = month ? 'MMMM' : undefined;
  const dayFormat = day ? 'do' : undefined;
  const yearFormat = year ? 'yyyy' : undefined;

  return format(
    d,
    [dayOfWeekFormat, [monthFormat, dayFormat].filter(Boolean).join(' '), yearFormat]
      .filter(Boolean)
      .join(', ')
  );
};
