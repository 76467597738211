import { observer } from 'mobx-react';

import { IFetchState } from '@/store/models/fetch-state';

import Notification from '@/components/notification';
import Spinner from '@/components/spinner';

interface FetchSuspenseProps {
  state: IFetchState;
  pendingRender?: React.ReactNode;
  rejectedRender?: (state: IFetchState) => React.ReactNode;
  pendingWrapper?: (props: { children: React.ReactNode }) => React.ReactElement;
  rejectedWrapper?: (props: { children: React.ReactNode }) => React.ReactElement;
  children: React.ReactNode;
}

export const FetchSuspense: React.FC<FetchSuspenseProps> = observer((props) => {
  if (props.state.isPending) {
    if (props.pendingRender) {
      return <>{props.pendingRender}</>;
    }

    const pendingSpinner = (
      <div className="u-flex u-justify-center u-py-4">
        <Spinner />
      </div>
    );

    if (props.pendingWrapper) {
      const Wrapper = props.pendingWrapper;

      return <Wrapper>{pendingSpinner}</Wrapper>;
    }

    return pendingSpinner;
  }

  if (props.state.isRejected) {
    if (props.rejectedRender) {
      return <>{props.rejectedRender(props.state)}</>;
    }

    const rejectedNotification = (
      <Notification type="error">
        {props.state.error && <p>{props.state.error}</p>}
        {props.state.hasFieldErrors && (
          <ul>
            {Array.from(props.state.fieldErrors.values()).map((error, index) => {
              <li key={index}>{error}</li>;
            })}
          </ul>
        )}
      </Notification>
    );

    if (props.rejectedWrapper) {
      const Wrapper = props.rejectedWrapper;

      return <Wrapper>{rejectedNotification}</Wrapper>;
    }

    return rejectedNotification;
  }

  return <>{props.children}</>;
});

export default FetchSuspense;
